import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { commonLabelConstants } from 'src/app/constants/LabelConstants';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { CommonService } from 'src/app/services/common/common.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // Common Properties
  public focus: boolean = false;
  LabelConstants: any;
  UserName: any;
  UserId: any;
  UserImage: any;
  isStudent:boolean = false;
  selectedLanguage: string = 'fr';


  constructor(
    private router: Router,
    private storageService: StorageService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toster: ToastrService,
    private apiUrl: ApiUrlHelper,
    private dialog: MatDialog,
    private translate: TranslateService
  ) { 
    const savedLanguage = localStorage.getItem('selectedLanguage') || 'fr';
    this.selectedLanguage = savedLanguage;
    this.translate.setDefaultLang(this.selectedLanguage);
    this.translate.use(this.selectedLanguage);

  }

  ngOnInit() {
    this.UserName = this.storageService.getValue('userName');
    this.UserId = this.storageService.getValue('userId');
    this.UserImage = this.storageService.getValue('userImage');
    this.LabelConstants = commonLabelConstants;
    let roleId:any = this.commonService.Decrypt(localStorage.getItem('userRole'));
    if(roleId == 3){
       this.isStudent = true;
    }
    this.getUserDataById();
    this.commonService.getUserImage().subscribe(image => {
      if (image) {
        this.UserImage = image;
        localStorage.setItem('userImage', image);
      }
    });
  }

  //handle image error
  handleImageError() {
    this.UserImage = '../../../assets/img/default-user-profile.svg';
  }

  LogOut(action: any) {
        let apiUrl = this.apiUrl.apiUrl.Login.logout;
        const translatedHeading = this.translate.instant('sidebar.logout');
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '460px',
          data: { translatedHeading, heading : translatedHeading, isSession : false },
          disableClose: true,
          autoFocus: false
        });
     if(localStorage.getItem('authToken')!=null)
      {
        dialogRef.afterClosed().subscribe((result) => {
          if (result === true) {
            this.spinner.show();
            this.commonService.doPost(apiUrl, this.UserId).pipe().subscribe({
              next: (response) => {
                if (response.success) {
                  this.router.navigate(['/login']);
                  localStorage.clear();
                  this.spinner.hide()
                }
                else {
                  this.toster.error(response.message);
                  this.spinner.hide()
                }
              },
              error: (err) => {
                this.spinner.hide();
              }
            })
          }
        });
      }
      else
      {
        this.spinner.hide()
        this.router.navigate(['/login']);
         localStorage.clear()
      }
  }

  NavigateToProfile() {
    this.router.navigate(['/myaccount'])
  }

  getUserDataById() {
    let api = this.apiUrl.apiUrl.User.GetUserById.replace('{Id}', this.UserId);
    this.commonService.doGet(api).pipe().subscribe({
      next: (response) => {
        if (response.success) {
          this.UserImage = response.data.images
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.log(err);
      }
    })
  }

  onLanguageChange() {
    this.translate.use(this.selectedLanguage);
    localStorage.setItem('selectedLanguage', this.selectedLanguage);
  }

  getSelectedLanguageLogo(): string {
    return this.selectedLanguage === 'en' ? '../../../assets/icons/united-kingdom.png' : '../../../assets/icons/france.png';
  }
  
}